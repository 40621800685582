import { SvgIcon } from "@material-ui/core";
import React from "react";
import IconSource from "@tdcerhverv/parrotfish/icons/Search";
import { TDCIconProps } from "../TDCIcon";

export const SearchIcon: React.FC<TDCIconProps> = React.memo(
  (props: TDCIconProps) => (
    <SvgIcon component={IconSource} viewBox="0 0 48 48" {...props} />
  )
);

export default SearchIcon;
